import * as React from 'react';
import styled from 'styled-components/macro';
import { FormattedMessage } from 'react-intl';
import { Helmet } from 'react-helmet';

import StoreContainer from '../../components/StoreContainer';
import SubscriptionBlock from '../../components/SubscriptionBlock';
import LicenseBlock from '../../components/LicenseBlock';
import FAQ from '../../components/FAQBlock';
import FooterContentForLicenses from '../../components/FooterContentForLicenses';

import { OmniGraffleLicenses } from '../../constants/products';
import * as vars from '../../styles/variables';

const AppDescription = styled.p`
    max-width: 800px;
    margin: 10px auto;
    color: ${vars.whiteColor};
`;

const OGContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 40px 0px;
    gap: 40px;
`;

const OmniGraffle: React.FC = () => {
    const [licenseType, setLicenseType] = React.useState('subscription');

    React.useEffect(() => {
        if (window.location.pathname.includes('subscription')) {
            setLicenseType('subscription');
        } else if (window.location.pathname.includes('license')) {
            setLicenseType('ownership');
        }
        console.log(licenseType);
    }, []);

    return (
        <StoreContainer>
            <Helmet>
                <meta
                    name="description"
                    content="One-off payment and subscription options are available for OmniGraffle. Save up to 50% with upgrade discounts. 14-day trials available without purchase."
                />
            </Helmet>
            <AppDescription>
                <FormattedMessage id='OmniGraffleDescription' />{' '}
            </AppDescription>
            <OGContainer>
                <LicenseBlock
                    backgroundColor={vars.backgroundGreenGradient}
                    borderTopGradient={vars.greenBorderGradientTop}
                    buttonColor={vars.omnigraffleButtonColor}
                    color={vars.green}
                    dateAddedToCart={0}
                    displayName='OmniGraffle 7 Licenses for Mac'
                    header={'OmniGraffle 7 for Mac Perpetual License'}
                    subheader={['v7 Standard License', 'v7 Pro License']}
                    products={OmniGraffleLicenses}
                    productNumber={7}
                    productVersion='v7'
                    quantity={1}
                    requirementString={'Requires macOS 12, iOS/iPadOS 16, visionOS 1.0, or newer'}
                    shortName='omnigraffle'
                    standardOrPro='PRO'
                />
                <SubscriptionBlock
                    offtw={false}
                    backgroundColor={vars.backgroundGreenGradient}
                    borderSideGradient={vars.greenBorderGradientSide}
                    buttonColor={vars.omnigraffleButtonColor}
                    color={vars.green} /* box shadow color */
                    header={'OmniGraffle Subscription'}
                    platform="Mac, iPhone, and iPad"
                    productDisplayName='OmniGraffle Subscription'
                    productName="OmniGraffle"
                    shortName='omnigraffle'
                    requirementString='Requires macOS 12, iOS/iPadOS 16, visionOS 1.0, or newer'
                    payInterval={false}
                    quantity={1}
                    blockableVendingIds={['OMNIGRAFFLE_ALL']}
                    yearlyPrice={12499}
                    yearlyStripeID='STRIPE_OMNIGRAFFLE_ANNUAL'
                />
                <FAQ />
            </OGContainer>
            <FooterContentForLicenses appStoreLink='https://www.omnigroup.com/download/appstore/omnigraffle' />
        </StoreContainer>
    );
};

export default OmniGraffle;
