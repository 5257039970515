import * as React from 'react';
import styled from 'styled-components';

import LicenseCard from './LicenseCard';
import ProductInformation from './ProductFeatures';

const BuyLicenseWrapper = styled.div``;

const BuyLicenseContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 20px;
    padding: 0 30px 30px;

    @media screen and (max-width: 670px) {
        grid-template-columns: none;
        column-gap: 0;
        grid-template-rows: 1fr 1fr;
        row-gap: 20px;
        justify-content: center;
    }
`;

const CompareFeatures = styled.div`
    text-align: center;
    
    > a {
        text-decoration: none;
        color: black;
        font-size: 16px;
    }
`;

interface LicenseBlockProps {
    borderBackground: string;
    buttonColor: string;
    dateAddedToCart: number;
    displayName: string;
    features: Array<string>;
    finishedLoading: boolean;
    header: Array<string>;
    inCart: boolean;
    products: Array<OmniStore.ProductInfo>;
    shortName: 'omnigraffle' | 'omnifocus' | 'omnioutliner' | 'omniplan';
    selection: 'PRO' | 'STANDARD'
    proQuantity: number;
    proQuantityError: boolean;
    standardQuantityError: boolean;
    standardQuantity: number;
    usingUpgradePricing: boolean;

    addLicenseToCartHandler: (id: string, price: number) => Promise<void>;
    setSelection: (value: 'STANDARD' | 'PRO') => void;
    setStandardQuantity: (value: number) => void;
    setStandardQuantityError: (value: boolean) => void;
    setProQuantity: (value: number) => void;
    setProQuantityError: (value: boolean) => void;
}

const BuyLicenseBlock: React.FC<LicenseBlockProps> = (props) => {
    return (
        <BuyLicenseWrapper>
            <BuyLicenseContainer>
                {props.products.map((license) => {
                    return (
                        <LicenseCard
                            key={license.name}
                            borderBackground={props.borderBackground}
                            buttonColor={props.buttonColor}
                            licenseName={license.name}
                            licenseType={license.licenseType}
                            quantity={license.licenseType === 'PRO' ? props.proQuantity : props.standardQuantity}
                            price={license.price}
                            inCart={props.inCart}
                            vendingID={license.vending_product_id}
                            finishedLoading={props.finishedLoading}
                            shortName={props.shortName}
                            addLicenseToCartHandler={props.addLicenseToCartHandler}
                            setProQuantity={props.setProQuantity}
                            setStandardQuantity={props.setStandardQuantity}
                            setSelection={props.setSelection}
                        />
                    )
                })}
            </BuyLicenseContainer>
            <CompareFeatures>
                <a href={`https://www.omnigroup.com/${props.shortName}/features`}>Compare features &gt;</a>
            </CompareFeatures>
            <ProductInformation features={props.features} purchaseType='license'/>
        </BuyLicenseWrapper>
    );
}     

export default BuyLicenseBlock;

