import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import styled, {css} from 'styled-components';

import PriceComponent from './PriceComponent';
import Form from './Form';
import * as vars from '../styles/variables';

interface StyleProps {
    borderBackground: string;
    product: string;
}

const ContentWrapper = styled.div`
    border: 1px solid black;
    background-color: ${vars.backgroundWhite};
    border-radius: 10px;
    height: max-content;
    max-width: 340px;
`;

const BorderTop = styled.div<StyleProps>`
    background: ${props => props.borderBackground};
    ${(props) => props.product !== 'omnifocus' 
        && css`background-blend-mode: difference;`
    }
    background-size: cover;
    height: 24px;
    border-radius: 10px 10px 0 0;
`;

const Header = styled.div`
    font-size: 24px;
    font-weight: 500;
    text-align: center;
`;

const UpgradeContainer = styled.div`
    padding: 20px;
`;  

const Divider = styled.hr`
    margin: 20px 0;
`;

interface FormProps {
    borderBackground: string;
    buttonColor: string;
    currentVersion: boolean;
    currentVersionUpgrade?: Array<OmniStore.UpgradeInfo>;
    fullPrice?: number;
    inCart: OmniStore.InCartUpgradeInfo;
    licenseType: 'Standard' | 'Pro' | 'Essentials';
    productVersion: string;
    shortName: string;
    availLicenseBlurb: boolean;
    stdUpgrades?: Array<OmniStore.UpgradeInfo>;
    upgradeItems?: Array<OmniStore.UpgradeInfo>;
    upgradePrice?: number;

    addUpgradesToCart: (type: string) => void;
    handleChange: (e: React.FormEvent<HTMLInputElement>, product: OmniStore.UpgradeInfo, upgradeType: string) => void;
}

const UpgradeForm: React.FC<FormProps> = (props: FormProps) => {
    return (
        <ContentWrapper>
            <BorderTop product={props.shortName} borderBackground={props.borderBackground}></BorderTop>
            <UpgradeContainer>
                <Header>
                    <FormattedMessage
                        id="upgradesHeader"
                        values={{
                            licenseType: props.licenseType,
                            version: props.productVersion
                        }}
                    />
                </Header>
                {!props.upgradeItems && props.currentVersion ? (
                    null
                ) : (
                    <>  
                        <PriceComponent
                            currentVersion={props.currentVersion}
                            fullPrice={props.fullPrice}
                            shortName={props.shortName}
                            showFullPrice={true}
                            upgradePrice={props.upgradePrice}
                            upgradeType={props.licenseType === 'Essentials' ? 'Standard' : props.licenseType}
                        />
                        <Form
                            availLicenseBlurb={props.availLicenseBlurb}
                            buttonColor={props.buttonColor}
                            inCart={props.inCart}
                            productVersion={props.productVersion}
                            shortName={props.shortName}
                            upgradeItems={props.upgradeItems ?? []}
                            upgradeType={props.licenseType === 'Essentials' ? 'Standard' : props.licenseType} 
                            addUpgradesToCart={props.addUpgradesToCart}
                            handleChange={props.handleChange}
                        />
                    </> 
                    )
                }
                {(props.availLicenseBlurb && props.currentVersion) && (
                    <Divider/>
                )}
                {props.currentVersion && props.currentVersionUpgrade.length > 0 && (
                    <>
                        <PriceComponent
                            currentVersion={props.currentVersion}
                            fullPrice={props.fullPrice}
                            shortName={props.shortName}
                            upgradePrice={props.upgradePrice}
                            upgradeType={'Pro'}
                        />
                        <Form
                            availLicenseBlurb={false}
                            buttonColor={props.buttonColor}
                            inCart={props.inCart}
                            productVersion={props.productVersion}
                            shortName={props.shortName}
                            upgradeItems={props.currentVersionUpgrade}
                            upgradeType={'Pro'}
                            addUpgradesToCart={props.addUpgradesToCart}
                            handleChange={props.handleChange}
                        />
                    </>
                )}
            </UpgradeContainer>
        </ContentWrapper>
    )
}

export default UpgradeForm;