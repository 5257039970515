import * as React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components/macro';
import { FormattedMessage } from 'react-intl';
import { Helmet } from 'react-helmet';

import StoreContainer from '../../components/StoreContainer';
import SubscriptionBlock from '../../components/SubscriptionBlock';
import LicenseBlock from '../../components/LicenseBlock';
import FAQBlock from '../../components/FAQBlock';
import FooterContentForLicenses from '../../components/FooterContentForLicenses';

import { OmniFocusLicenses } from '../../constants/products';
import * as vars from '../../styles/variables';

const OFContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 40px 0px;
    gap: 40px;
`;

const AppDescription = styled.p`
    max-width: 800px;
    margin: 10px auto;
    color: ${vars.whiteColor};
`;

const OmniFocus: React.FC = () => {
    const [licenseType, setLicenseType] = React.useState('subscription');
    const purchaseApiUrl = useSelector((state: OmniStore.GlobalReduxState) => state.apiURLs.purchaseAPIURL);

    React.useEffect(() => {
        if (window.location.pathname.includes('subscription')) {
            setLicenseType('subscription');
        } else if (window.location.pathname.includes('license')) {
            setLicenseType('ownership');
        }
        console.log(licenseType);
    }, []);

    return (
        <StoreContainer>
            <Helmet>
                <meta
                    name="description"
                    content="One-off payment and subscription options are available for OmniFocus. Save up to 50% with upgrade discounts. 14-day trials available without purchase."
                />
            </Helmet>
            <AppDescription>
                <FormattedMessage id='OmniFocusDescription' />{' '}
            </AppDescription>
            <OFContainer>
                <LicenseBlock 
                    backgroundColor={vars.backgroundPurpleGradient}
                    borderTopGradient={vars.purpleBorderGradientTop}
                    buttonColor={vars.omnifocusButtonColor}
                    color={vars.purple}
                    dateAddedToCart={0}
                    displayName='OmniFocus 4 Licenses'
                    header='OmniFocus 4 Perpetual License'
                    subheader={['v4 Standard License', 'v4 Pro License']}
                    platform='Mac, iPhone, iPad, Apple Watch, and Apple Vision Pro'
                    products={OmniFocusLicenses}
                    productVersion="v4"
                    productNumber={4}
                    quantity={1}
                    requirementString="Requires macOS 14, iOS/iPadOS 17, visionOS 1.0, or newer"
                    shortName='omnifocus'
                    standardOrPro='PRO'
                    // specialOffer={'Buy today, receive a free upgrade to OmniFocus 4 when it ships.'}
                /> 
                <SubscriptionBlock
                    offtw={true}
                    backgroundColor={vars.backgroundPurpleGradient}
                    borderSideGradient={vars.purpleBorderGradientSide}
                    buttonColor={vars.omnifocusButtonColor}
                    color={vars.purple}
                    header={"Web Access"}
                    platform={"Mac, iPhone, iPad, Apple Watch, Apple Vision Pro, and Web"}
                    productName="OmniFocus"
                    productDisplayName={'OmniFocus Web Add-On Subscription'}
                    quantity={1}
                    shortName="omnifocus"
                    blockableVendingIds={['OMNIFOCUS_ALL']}
                    payInterval={true}
                    monthlyPrice={499}
                    monthlyStripeID={'STRIPE_OMNIFOCUS_WEB_MONTHLY'}
                    yearlyPrice={4999}
                    yearlyStripeID={'STRIPE_OMNIFOCUS_WEB_ANNUAL'}
                />
                <SubscriptionBlock
                    offtw={false}
                    backgroundColor={vars.backgroundPurpleGradient}
                    borderSideGradient={vars.purpleBorderGradientSide}
                    blockableVendingIds={['OMNIFOCUS_ALL']}
                    buttonColor={vars.omnifocusButtonColor}
                    color={vars.purple}
                    header={"OmniFocus Subscription"}
                    subheader="(Includes OmniFocus for the Web)"
                    platform={"Mac, iPhone, iPad, Apple Watch, Apple Vision Pro, and Web"}
                    productName="OmniFocus"
                    productDisplayName={"OmniFocus Subscription"}
                    payInterval={false}
                    quantity={1}
                    requirementString="Requires macOS 14, iOS/iPadOS 17, visionOS 1.0, or newer"
                    shortName="omnifocus"
                    yearlyPrice={9999}
                    yearlyStripeID={
                        purchaseApiUrl.includes('test')
                        // ? 'TEST_OMNIFOCUS_WEEKLY'
                        ? 'STRIPE_OMNIFOCUS_ALL_ANNUAL'
                        : 'STRIPE_OMNIFOCUS_ALL_ANNUAL'
                    }
                />
                <FAQBlock/>
            </OFContainer>
            <FooterContentForLicenses appStoreLink='https://www.omnigroup.com/download/appstore/omnifocus' />
        </StoreContainer>
    );
};

export default OmniFocus;
