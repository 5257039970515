import * as React from 'react';
import styled, {css} from 'styled-components';

interface StyleProps {
    inputChecked?: boolean;
    leftLabel?: string;
    rightLabel?: string;
    offtw?: boolean;
}

const ToggleContainer = styled.div<StyleProps>`
    display: flex;
    justify-content: center;
    padding: 30px;
    line-height: 2.5em;
    margin: auto;
    width: 300px;
    font-weight: 500;

    #checkbox {
        display: none;

        &:checked + .slider {
            background-color: transparent;
        }
        &:checked + .slider:before {
            transform: translateX(142px);
            text-align: center;
            width: 160px;
        }
    }

    .slider {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        transition: .4s;
        background-color: transparent;
        cursor: pointer;
        font-size: 18px;

        &:before {
            position: absolute;
            top: 0;
            left: -1px;
            height: 40px;
            width: 150px;
            transition: .5s;
            background-color: black;
            color: white;
            border-radius: 20px;
            text-align: center;
        }
    }
    .round {
        border-radius: 34px;
    }
    .left-label {
        float: right;
    }

    @media screen and (max-width: 440px) {
        padding: 20px 0px;
    }
`;

const Switch = styled.label<StyleProps>`
    display: inline-block;
    position: relative;
    height: 40px;
    width: 300px;
    border: 1px solid black;
    border-radius: 20px;
    padding: ${(props) => props.offtw ? '0 20px' : '0 40px 0 60px'};
    background-color: rgb(255, 252, 240);

    > span {
        font-size: 18px;
        color: #B7B5AC;
    }
`;

const Slider = styled.div<StyleProps>`
    &:before {
        ${(props) => props.leftLabel && css`
            content: '${props.leftLabel}';
            `};
        }
        `;

const ToggleInput = styled.input<StyleProps>`
    &:checked + .slider:before {
        ${(props) => props.rightLabel && css`
            content: '${props.rightLabel}';
        `};
    }
`;

interface ToggleProps {
    showContent: boolean;
    leftLabel: string;
    rightLabel: string;
    offtw: boolean;

    toggleChange: (display: boolean) => void;
}

const ToggleSwitch: React.FC<ToggleProps> = (props) => {
    return (
        <ToggleContainer 
            aria-label="Buy or Upgrade Options"
            role="button"
            offtw={props.offtw}
        >
            <Switch className="switch" offtw={props.offtw}>
                <span aria-label={`${props.leftLabel} option}`}>{props.leftLabel}</span>
                <ToggleInput 
                    type="checkbox" 
                    id="checkbox" 
                    checked={props.showContent}
                    rightLabel={props.rightLabel}
                    onChange={() => props.toggleChange(!props.showContent)}
                />
                <Slider 
                    className="slider round" 
                    leftLabel={props.leftLabel}
                ></Slider>
                <span className="left-label" aria-label={`${props.rightLabel} option}`}>{props.rightLabel}</span>
            </Switch> 
        </ToggleContainer>
    )
}

export default ToggleSwitch;