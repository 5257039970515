import * as React from 'react';
import styled from 'styled-components/macro';
import { FormattedMessage } from 'react-intl';
import { Helmet } from 'react-helmet';

import StoreContainer from '../../components/StoreContainer';
import SubscriptionBlock from '../../components/SubscriptionBlock';
import LicenseBlock from '../../components/LicenseBlock';
import FAQ from '../../components/FAQBlock';
import FooterContentForLicenses from '../../components/FooterContentForLicenses';

import { OmniOutlinerLicenses } from '../../constants/products';
import * as vars from '../../styles/variables';

const AppDescription = styled.p`
    max-width: 800px;
    margin: 10px auto;
    color: ${vars.whiteColor};
`;

const OOContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 40px 0px;
    gap: 40px;
`;

const OmniOutliner: React.FC = () => {
    const [licenseType, setLicenseType] = React.useState('subscription');

    React.useEffect(() => {
        if (window.location.pathname.includes('subscription')) {
            setLicenseType('subscription');
        } else if (window.location.pathname.includes('license')) {
            setLicenseType('ownership');
        }
        console.log(licenseType);
    }, []);

    const outlinerMessage = 'OmniOutliner 5 Essentials is a new edition of OmniOutliner that is more streamlined than standard editions of the past at a lower price. No upgrade discount is available but purchasing via this upgrade will make you eligible for a reduced cost upgrade to v5 Pro.'

    return (
        <StoreContainer>
            <Helmet>
                <meta
                    name="description"
                    content="One-off payment and subscription options are available for OmniOutliner. Save up to 50% with upgrade discounts. 14-day trials available without purchase."
                />
            </Helmet>
            <AppDescription>
                <FormattedMessage id='OmniOutlinerDescription' />{' '}
            </AppDescription>
            <OOContainer>
                <LicenseBlock
                    backgroundColor={vars.backgroundOrangeGradient}
                    borderTopGradient={vars.orangeBorderGradientTop}
                    buttonColor={vars.omnioutlinerButtonColor}
                    color={vars.orange}
                    dateAddedToCart={0}
                    displayName='OmniOutliner 5 Licenses for Mac'
                    header='OmniOutliner 5 for Mac Perpetual License'
                    subheader={['v5 Essentials License', 'v5 Pro License']}
                    omnioutlinerMessage={outlinerMessage}
                    products={OmniOutlinerLicenses}
                    productNumber={5}
                    productVersion='v5'
                    quantity={1}
                    requirementString={'Requires macOS 12, iOS/iPadOS 16, visionOS 1.0, or newer'}
                    shortName='omnioutliner'
                    standardOrPro='PRO'
                /> 
                <SubscriptionBlock
                    offtw={false}
                    backgroundColor={vars.backgroundOrangeGradient}
                    borderSideGradient={vars.orangeBorderGradientSide}
                    buttonColor={vars.omnioutlinerButtonColor}
                    blockableVendingIds={['OMNIOUTLINER_ALL']}
                    color={vars.orange}
                    productDisplayName='OmniOutliner Subscription'
                    header={'OmniOutliner Subscription'}
                    payInterval={false}
                    platform="Mac, iPhone, and iPad"
                    productName="OmniOutliner"
                    quantity={1}
                    requirementString='Requires macOS 12, iOS/iPadOS 16, or newer'
                    shortName='omnioutliner'
                    yearlyPrice={4999}
                    yearlyStripeID='STRIPE_OMNIOUTLINER_ANNUAL'
                /> 
                <FAQ/>
            </OOContainer> 
            <FooterContentForLicenses appStoreLink='https://www.omnigroup.com/download/appstore/omnioutliner' />
        </StoreContainer>
    );
};

export default OmniOutliner;
