import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import styled, {css} from 'styled-components';

import NewButton from '../components/NewButton';
import * as vars from '../styles/variables';

import { toggleSignInForm } from '../actions/accounts';

interface StyleProps {
    backgroundColor?: string;
    offtw?: boolean;
    product?: string;
    borderBackground?: string;
}


const DiscountContainer = styled.div<StyleProps>`
    background-color: ${vars.backgroundWhite};
    border-radius: 9px;
    border: 1px solid black;
    margin: 30px;
    padding-bottom: 10px;

    > p {
        font-size: 16px;
        padding: 0px 30px;
    }

    @media screen and (max-width: 480px) {
        > p {
            padding: 0px 1.5em;
            line-height: 1.3;
        }
    }
`;

const BorderTop = styled.div<StyleProps>`
    background: ${(props) => props.borderBackground};
    ${(props) => props.product !== 'omnifocus' 
        && css`background-blend-mode: difference;`
    }
    background-size: cover;
    height: 24px;
    border-radius: 10px 10px 0 0;
`;

const DiscountHeader = styled.div`
    font-size: 24px;
    font-weight: 500;
    text-align: center;
    padding: 20px;
    line-height: 1.2;

    @media screen and (max-width: 480px) {
        padding: 10px 20px;
    }
`;

const AccountSection = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    
    @media screen and (max-width: 480px) {
        padding: 0 1.5em;
    }
`;

const SignedInBlurb = styled.div`
    font-size: 16px;
    padding: 1em;
`;

const Border = styled.div<StyleProps>`
    border-top: 1px solid ${vars.transparentWhite};
`;
 
interface DiscountBlockProps {
    blockHeader?: string;
    borderBackground: string;
    buttonColor: string;
    className?: string;
    description: string;
    offtw?: boolean;
    product: 'omnigraffle' | 'omnifocus' | 'omnioutliner' | 'omniplan';
    signIn: string;
}

const DiscountBlock: React.FC<DiscountBlockProps> = (props: DiscountBlockProps) => {
    const dispatch = useDispatch();
    const has_auth = useSelector((state: OmniStore.GlobalReduxState) => state.accounts.has_auth);
    const showSigninForm = useSelector((state: OmniStore.GlobalReduxState) => state.accounts.showSigninForm);

    return (
        <DiscountContainer>
            <BorderTop product={props.product} borderBackground={props.borderBackground}></BorderTop>
            <DiscountHeader>{props.blockHeader}</DiscountHeader>
            <p>
                {has_auth ? 
                    <FormattedMessage 
                        id="upgradeDiscountDescriptionSignedIn"
                        values={{
                            OmniAccount: (
                                <a href={'https://accounts.omnigroup.com'}>Omni Account</a>
                            )
                        }}
                    /> 
                    : props.description 
                }
            </p>
            <AccountSection>
                {has_auth ? (
                    <>
                        <SignedInBlurb>Any eligible discounts will be shown in the cart.</SignedInBlurb>
                        <Border product={props.product}></Border>
                        <a id='register-link' href={'https://store.omnigroup.com/register-license'}>Need to register a license key?</a>
                    </>
                ) : (
                    <>
                        <NewButton
                            id="DiscountSignInButton"
                            color={props.buttonColor}
                            omniplan={props.product === 'omniplan' ? true : false }
                            onClick={() => dispatch(toggleSignInForm({display: !showSigninForm}))}
                        >
                            {props.signIn}
                        </NewButton>
                        <a href="https://accounts.omnigroup.com/register">Create an Omni Account</a>
                    </>
                )}
            </AccountSection>
        </DiscountContainer>
    )
};

export default DiscountBlock;