import React from 'react';
import styled from 'styled-components';

import * as vars from '../styles/variables';

interface StyleProps {
    purchaseType: string;
}

const ProductInfoWrapper = styled.div<StyleProps>`
    background-color: ${vars.backgroundWhite};
    border: 1px solid ${vars.blackColor};
    margin: ${(props) => props.purchaseType === 'license' 
        ? '30px'
        : '0px 30px 30px 30px'
    };
    border-radius: 10px;
    
    > ul {
        margin-right: 5px;
    }
`;

interface ProductFeaturesProps {
    purchaseType: string;
    features: Array<string>;
    focus?: string;
}

const ProductFeatures: React.FC<ProductFeaturesProps> = (props) => {
    return (
        <ProductInfoWrapper purchaseType={props.purchaseType}>
            <ul>
                {props.features.map((item, key) => {
                    return <li key={key}>{item}</li>
                })}
                {(props.focus && props.focus === 'omnifocus') &&  (
                    <li>
                        OmniFocus for the Web requires a v3 
                        or newer OmniFocus database format and an active 
                        connection with a JavaScript enabled web browser 
                        (<a href="https://web.omnifocus.com/ctp/">Check browser compatibility</a>)
                    </li>
                )}
            </ul>
        </ProductInfoWrapper>
    )
};

export default ProductFeatures;