export const OmniFocusLicenses: Array<OmniStore.ProductInfo> = [
    {
        licenseType: 'STANDARD',
        name : 'v4 Standard License',
        price: 7499,
        quantity: 1,
        vending_product_id: 'STRIPE_OMNIFOCUS_4_STD'
    },
    {
        licenseType: 'PRO',
        name: 'v4 Pro License',
        price: 14999,
        pro: true,
        quantity: 1,
        vending_product_id: 'STRIPE_OMNIFOCUS_4_PRO'
    }
];

export const OmniGraffleLicenses: Array<OmniStore.ProductInfo> = [
    {
        licenseType: 'STANDARD',
        name : 'v7 Standard License',
        price: 14999,
        quantity: 1,
        vending_product_id: 'STRIPE_OMNIGRAFFLE_7_MAC_STD'
    },
    {
        licenseType: 'PRO',
        name: 'v7 Pro License',
        price: 24999,
        quantity: 1,
        vending_product_id: 'STRIPE_OMNIGRAFFLE_7_MAC_PRO'
    }
];

export const OmniOutlinerLicenses: Array<OmniStore.ProductInfo> = [
    {
        licenseType: 'STANDARD',
        name : 'v5 Essentials License',
        price: 1999,
        quantity: 1,
        vending_product_id: 'STRIPE_OMNIOUTLINER_5_MAC_STD'
    },
    {
        licenseType: 'PRO',
        name: 'v5 Pro License',
        price: 9999,
        pro: true,
        quantity: 1,
        vending_product_id: 'STRIPE_OMNIOUTLINER_5_MAC_PRO'
    }
];

export const OmniOutlinerFeatures: Array<string> = [
    'Purchase once to own this version forever', 
    'Includes all 5.x updates',
    "Future major upgrades available at a discount",
    'Requires macOS 12, or newer',
    '30-day money back guarantee'
];

export const OmniPlanLicenses: Array<OmniStore.ProductInfo> = [
    {
        licenseType: 'STANDARD',
        name : 'v4 Standard License',
        price: 19999,
        quantity: 1,
        vending_product_id: 'STRIPE_OMNIPLAN_4_MAC_STD'
    },
    {
        licenseType: 'PRO',
        name: 'v4 Pro License',
        price: 39999,
        quantity: 1,
        vending_product_id: 'STRIPE_OMNIPLAN_4_MAC_PRO'
    }
];

export const LicenseFeatures = (requirementString: string) => {
    return [
        "Future major upgrades available at a discount",
        requirementString, 
        '30-day money back guarantee'
    ];
};

export const SubscriptionFeatures = (requirementString?: string) => {
    if (requirementString) {
        return [
            'Auto-renews till canceled',
            requirementString,
        ]
    }
    return ['Auto-renews till canceled'];
};

