import * as React from 'react';
import { useSelector } from 'react-redux';
import styled, {css} from 'styled-components';
import { FormattedNumber } from "react-intl";
import { Link } from 'react-router-dom';

import NewButton from "./NewButton";
import * as vars from '../styles/variables';

interface StyleProps {
    borderBackground: string;
    product: string;
}

const CardContainer = styled.div`
    display: grid;
    grid-template-columns: 24px 1fr;
    max-width: 340px;
    background-color: ${vars.backgroundWhite};
    justify-self: center;
    border: 1px solid ${vars.blackColor};
    border-radius: 10px;
    width: 100%;
    margin: auto;
`;

const CardSideBorder = styled.div<StyleProps>`
    background: ${(props) => props.borderBackground};   
    background-size: cover;
    ${(props) => props.product !== 'omnifocus' && 
        css`background-blend-mode: difference;`
    };
    border-radius: 9px 0 0 9px;
`;

const CardSubscriptionInfo = styled.div`
    text-align: center;
`;

const Header = styled.div`
    font-size: 24px;
    font-weight: 500;
    padding: 20px;
`;

const Price = styled.div`
    font-size: 28px;
    font-weight: 500;
    line-height: 1.2;

    > span, > div {
        font-size: 16px;
        font-weight: 500;
    }
`;

const AddToCart = styled.form`
    display: flex;
    justify-content: center;
    padding: 15px;
`;

const Quantity = styled.input`
    width: 65px;
    padding: 6px 8px;
    margin: 10px 15px;
    font-family: quatro;
    font-size: 16px;
    line-height: 20px;
`;

const InCartLink = styled(Link)`
    text-decoration: none;
    color: ${vars.darkerGray};
`;

interface SubscriptionCardProps {
    borderBackground: string;
    buttonColor: string;
    offtw: boolean;
    payInterval: 'month' | 'year';
    price: number;
    productDisplayName: string;
    quantity: number;
    finishedLoading: boolean;
    cautiouslySubscribe: boolean;
    inCart: boolean;
    shortName: 'omnigraffle' | 'omnifocus' | 'omnioutliner' | 'omniplan';
    subscribed: boolean;
    addItemToCartHandler: () => void;
    setQuantity: (amount: number) => void;
}

const SubscriptionCard: React.FC<SubscriptionCardProps> = (props) => {
    const accountsApiURL = useSelector((state: OmniStore.GlobalReduxState) => state.apiURLs.accountsAPIURL);

    return (
        <CardContainer>
            <CardSideBorder product={props.shortName} borderBackground={props.borderBackground}></CardSideBorder>
            <CardSubscriptionInfo>
                {props.offtw ? (
                    <Header>OmniFocus for the Web Subscription</Header>
                ) : (
                    <Header>Annual Subscription</Header>
                )}
                <Price>
                    <FormattedNumber 
                        value={props.price/ 100}
                        style={`currency`}
                        currency='USD'
                    /> <span>USD</span>
                    <div>per {props.payInterval}</div>
                </Price>
                <AddToCart onSubmit={() => props.addItemToCartHandler()}>
                    {!props.inCart && !props.subscribed && (
                        <Quantity 
                            arial-label="Subscription quantity"
                            min={1}
                            placeholder={props.quantity ? props.quantity.toString() : '1'}
                            pattern='[0-9]*'
                            type='number'
                            onChange={(e) => props.setQuantity(Number(e.target.value))}
                        />
                    )} 
                    {props.finishedLoading && (
                        <>
                            {props.subscribed ? (
                                <p>
                                    <strong>
                                        This account has an active subscription. To add more
                                        seats, visit your{' '}
                                        <a href={accountsApiURL}>Omni Accounts</a> page.
                                    </strong>
                                </p>
                                ) : props.cautiouslySubscribe ? (
                                    <p style={{ fontWeight: 600 }}>
                                        You’re already subscribed, but if you think your
                                        subscription might lapse elsewhere, you can start a new
                                        subscription directly with us.
                                    </p>
                                ) : (
                                    <NewButton
                                        disabled={props.subscribed || props.inCart}
                                        id='AddSubscriptionToCartButton'
                                        color={props.buttonColor}
                                        omniplan={props.shortName === 'omniplan' ? true : false}
                                        onClick={() => props.addItemToCartHandler()}
                                    >
                                        {props.inCart ? (
                                            <InCartLink to={'/cart'}>In Cart</InCartLink>
                                        )   :   'Add to Cart'}
                                    </NewButton>
                                )
                            }
                        </>
                    )}
                </AddToCart>
            </CardSubscriptionInfo>
        </CardContainer>
    )
};

export default SubscriptionCard;