import * as React from 'react';
import styled from 'styled-components';
import { FormattedMessage, FormattedNumber } from 'react-intl';

const PriceContainer = styled.div`
    padding: 20px;
    text-align: center;

    #full-price {
        color: #878580;
        font-weight: 500;
        margin: 0;

        > span {
            font-size: 14px;
            padding: 0 10px;
        }
    }

    > p {
        font-size: 14px;
    }
`;

const Price = styled.div`
    font-weight: 500;
    font-size: 28px;
    text-align: center;
    position: relative;

    > span {
        font-size: 16px;
        padding: 0 10px;
    }

`;

interface PriceComponentProps {
    currentVersion: boolean;
    fullPrice?: number;
    shortName: string;
    showFullPrice?: boolean;
    upgradePrice: number;
    upgradeType: 'Standard' | "Pro" | "Current" | 'Partials';
    subHeader?: string;
}

const PriceComponent: React.FC<PriceComponentProps> = (props: PriceComponentProps) => {
    const { 
        fullPrice,
        shortName,
        upgradePrice,
        upgradeType, 
    } = props;

    const omniOutlinerStandard = shortName === 'omnioutliner' && upgradeType === 'Standard';

    return (
        <PriceContainer>
            <Price>
                <FormattedNumber
                    currency='USD'
                    style={`currency`}
                    value={upgradePrice/100}
                />
                <span>USD</span>
            </Price>
            {omniOutlinerStandard && (
                <p id="full-price">
                    <FormattedMessage id="noDiscountAvailable"/>
                </p>
            )}
            {(props.showFullPrice && (
                <p id="full-price">Full price: &nbsp;
                    <FormattedNumber
                        currency='USD'
                        style={`currency`}
                        value={fullPrice/100}
                    />
                    <span>USD</span>
                </p>
            ))}
        </PriceContainer>
    )
}

export default PriceComponent;