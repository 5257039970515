import React from "react";
import styled, {css} from 'styled-components';
import { FormattedNumber } from "react-intl";
import { Link } from 'react-router-dom';

import NewButton from "./NewButton";
import * as vars from '../styles/variables';


interface StyleProps {
    borderBackground: string;
    product: string;
}

const CardWrapper = styled.div`
    /* max-width: 340px; */
    background-color: ${vars.backgroundWhite};
    border-radius: 10px;
    text-align: center;
    border: 1px solid ${vars.blackColor};
    overflow-x: hidden;
`;

const CardBTopBorder = styled.div<StyleProps>`
    background: ${(props) => props.borderBackground};
    background-size: auto 100%;
    ${(props) => props.product !== 'omnifocus' && 
        css`background-blend-mode: difference;`
    };
    height: 24px;
    border-radius: 9px 9px 0 0;
`;

const CardHeader = styled.div`
    font-size: 24px;
    font-weight: 500;
    padding: 20px;
`;

const Price = styled.div`
    font-size: 28px;
    font-weight: 500;
    
    > span {
        font-size: 16px;
        font-weight: 500;
    }
`;

const AddToCart = styled.form`
    display: flex;
    justify-content: center;
    padding: 15px;
`;

const Quantity = styled.input`
    width: 65px;
    padding: 6px 8px;
    margin: 10px 15px;
    font-family: quatro;
    font-size: 16px;
    line-height: 20px;
`;

const ReviewCartLink = styled(Link)`
    text-decoration: none;
    color: ${vars.darkerGray};
`;


interface CardProps {
    borderBackground: string;
    buttonColor: string;
    licenseName: string;
    licenseType: 'PRO' | 'STANDARD';
    price: number;
    inCart: boolean;
    quantity: number;
    vendingID: string;
    finishedLoading: boolean;
    shortName: string;
    addLicenseToCartHandler: (id: string, price: number) => Promise<void>;
    setProQuantity: (value: number) => void;
    setStandardQuantity: (value: number) => void;
    setSelection: (value: 'STANDARD' | 'PRO') => void;
}

const LicenseCard: React.FC<CardProps> = (props) => {
    return (
        <CardWrapper>
            <CardBTopBorder product={props.shortName} borderBackground={props.borderBackground}></CardBTopBorder>
            <CardHeader>{props.licenseName}</CardHeader>
            <Price>
                <FormattedNumber 
                    value={props.price/ 100}
                    style={`currency`}
                    currency='USD'
                /> <span>USD</span>
            </Price>
            <AddToCart onSubmit={() => props.addLicenseToCartHandler(props.vendingID, props.price)}>
                {!props.inCart ? (
                    <Quantity 
                        aria-label="Traditional license quantity"
                        min={1}
                        placeholder={props.quantity ? props.quantity.toString() : '1'}
                        pattern='[0-9]*'
                        type='number'
                        // borderHighlight={props.licenseType === 'PRO' ? props.proQuantityError : props.standardQuantityError}
                        onChange={(e): void => props.licenseType === 'PRO' 
                            ? props.setProQuantity(Number(e.target.value))
                            : props.setStandardQuantity(Number(e.target.value)
                        )}
                    />
                ) : null}
                {props.finishedLoading && (
                    <NewButton
                        disabled={props.inCart}
                        id={`Add${props.licenseType}LicenseToCartButton`}
                        color={props.buttonColor}
                        omniplan={props.shortName === 'omniplan' ? true : false}
                        onClick={() => props.setSelection(props.licenseType)}
                    >
                        {props.inCart ? (
                            <ReviewCartLink to={'/cart'}>Review Cart</ReviewCartLink>
                        )   : 'Add to Cart'}
                    </NewButton>
                )}
            </AddToCart>
        </CardWrapper>
    )
};

export default LicenseCard;