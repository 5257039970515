import * as React from 'react';
import styled from 'styled-components/macro';
import * as vars from '../styles/variables';

export declare const voidPromise: (e: React.SyntheticEvent) => Promise<void>;
export declare const voidVoid: () => void;

interface NewButtonProps {
    alignRight?: boolean;
    buttonType?: string;
    center?: boolean;
    children?: React.ReactNode;
    danger?: boolean;
    disabled?: boolean;
    inline?: boolean;
    lightGray?: boolean;
    id: string;
    minWidth?: string;
    onClick?: typeof voidPromise | typeof voidVoid;
    selected?: boolean;
    showLoading?: boolean;
    someMargin?: boolean;
    type?: 'submit' | 'reset' | 'button';
    uppercase?: boolean;
    value?: string;
    color?: string;
    omniplan?: boolean;
    signIn?: boolean;
}

const StyledButton = styled.button<NewButtonProps>`
    padding: 10px 20px;
    height: initial;
    line-height: 22px;
    font-family: quatro, sans-serif;
    color: ${(props): string => 
        props.omniplan ? vars.whiteColor : 
        props.signIn ? vars.blackColor :
        vars.blackColor};
    background: ${(props): string =>
        props.color ? props.color :
        props.lightGray ? vars.lightGray : 
        props.signIn ? vars.whiteColor :
        vars.lightBlue};
    border-radius: 20px;
    cursor: pointer;
    display: block;
    display: ${(props): string => (props.inline ? 'inline' : 'block')};
    font-size: 16px;
    font-weight: 500;
    border: ${(props) => props.signIn ? `1px solid ${vars.darkGray}` : 0};
    margin: ${(props): string => 
        props.center ? '10px auto' : 
        props.signIn ? '2em auto' :
        '10px 0'};
    margin: ${(props): string | undefined =>
        props.someMargin && '10px 10px' };
    padding: 10px 20px;
    text-transform: ${(props): string =>
        props.uppercase ? 'uppercase' : 'initial'};
        
    min-width: ${(props): string | undefined => props.minWidth};
    /* max-width: ${(props): string | undefined => props.minWidth}; */

    &:hover {
        cursor: pointer;
    }

    &:disabled {
        /* background-color: rgba(0, 0, 0, 0.05); */
        /* border: 1px solid rgba(144, 144, 144, 0.95); */
        color: #404040;
        border: 1px solid ${vars.darkGray};
        background-color: ${vars.disabledLightGray};
        color: ${(props) => props.signIn ? vars.signinDisabledGray : vars.blackColor};
        font-weight: 400;
        cursor: initial;
    }

    &:disabled:active {
        /* background-color: rgba(0, 0, 0, 0.05); */
        background: ${(props): string => 
            props.danger
            ? vars.brightRed
            : props.signIn
            ? vars.whiteColor
            : vars.blue
        };
        color: ${vars.darkerGray};
    }

    &:active {
        background: ${(props): string => 
            props.danger
            ? vars.brightRed
            // : props.signIn
            // ? vars.whiteColor
            : vars.blue
        };
        color: ${vars.whiteColor};
    }
`;

const NewButton: React.FC<NewButtonProps> = ({
    children,
    ...props
}: NewButtonProps): JSX.Element => {
    return (
        <StyledButton
            disabled={props.disabled}
            className={
                props.showLoading === true
                    ? 'ld-ext-right running'
                    : 'ld-ext-right'
            }
            id={props.id}
            {...props}
        >
            {children}
            <div className='ld ld-ring ld-spin'></div>
        </StyledButton>
    );
};

export default NewButton;
