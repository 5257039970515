import * as React from 'react';
import styled from 'styled-components/macro';
import { FormattedMessage } from 'react-intl';
import { Helmet } from 'react-helmet';

import StoreContainer from '../../components/StoreContainer';
import SubscriptionBlock from '../../components/SubscriptionBlock';
import LicenseBlock from '../../components/LicenseBlock';
import FAQ from '../../components/FAQBlock';
import FooterContentForLicenses from '../../components/FooterContentForLicenses';

import { OmniPlanLicenses } from '../../constants/products';
import * as vars from '../../styles/variables';

const AppDescription = styled.p`
    max-width: 800px;
    margin: 10px auto;
    color: ${vars.whiteColor};
`;

const OPContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 40px 0px;
    gap: 40px;
`;

const OmniPlan: React.FC = () => {
    const [licenseType, setLicenseType] = React.useState('subscription');

    React.useEffect(() => {
        if (window.location.pathname.includes('subscription')) {
            setLicenseType('subscription');
        } else if (window.location.pathname.includes('license')) {
            setLicenseType('ownership');
        }
        console.log(licenseType);
    }, []);

    return (
        <StoreContainer>
            <Helmet>
                <meta
                    name="description"
                    content="One-off payment and subscription options are available for OmniPlan. Save up to 50% with upgrade discounts. 14-day trials available without purchase."
                />
            </Helmet>
            <AppDescription>
                <FormattedMessage id='OmniPlanDescription' />{' '}
            </AppDescription>
            <OPContainer>
                <LicenseBlock
                    backgroundColor={vars.backgroundYellowGradient}
                    borderTopGradient={vars.yellowBorderGradientTop}
                    buttonColor={vars.omniplanButtonColor}
                    color={vars.yellow}
                    dateAddedToCart={0}
                    displayName='OmniPlan 4 Licenses'
                    header='OmniPlan 4 Perpetual License'
                    subheader={['v4 Standard License', 'v4 Pro License']}
                    platform="Mac, iPhone, iPad, and Apple Vision Pro"
                    products={OmniPlanLicenses}
                    productNumber={4}
                    productVersion='v4'
                    quantity={1}
                    requirementString="Requires macOS 12, iOS/iPadOS 16, visionOS 1.0, or newer"
                    shortName='omniplan'
                    standardOrPro='PRO'
                    universalIOSCounterparts={['OMNIPLAN_4_PRO']}
                />
                <SubscriptionBlock
                    offtw={false}
                    blockableVendingIds={['OMNIPLAN_ALL']}
                    backgroundColor={vars.backgroundYellowGradient}
                    borderSideGradient={vars.yellowBorderGradientSide}
                    buttonColor={vars.omniplanButtonColor}
                    color={vars.yellow}
                    productDisplayName='OmniPlan Subscription'
                    header={'OmniPlan Subscription'}
                    quantity={1}
                    payInterval={false}
                    platform="Mac, iPhone, iPad, and Apple Vision Pro"
                    requirementString='Requires macOS 12, iOS/iPadOS 16.0, visionOS 1.0, or newer'
                    productName='OmniPlan'
                    shortName='omniplan'
                    yearlyPrice={19999}
                    yearlyStripeID='STRIPE_OMNIPLAN_ANNUAL'
                /> 
                <FAQ />
            </OPContainer>
            <FooterContentForLicenses appStoreLink='https://www.omnigroup.com/download/appstore/omniplan' />
        </StoreContainer>
    );
};

export default OmniPlan;
