import * as React from 'react';
import styled from 'styled-components';

import * as vars from '../styles/variables';

const FAQContainer = styled.div`
    max-width: 760px;
    width: 100%;
    background-color: ${vars.whiteColor};
    border-radius: 10px;
    border: 1px solid ${vars.darkGray};

    > ul > li > a {
        text-decoration: none;
    }

    > ul > li {
        padding: 0 10px 0 0;
    }
`;

const TopPortion = styled.div`
    background-color: ${vars.darkGray};
    border-radius: 5px 5px 0 0;
    color: white;
    padding: 10px;
    font-size: 18px;
    text-align: center;
`;

const FAQ = {
    //eslint-disable-next-line
    'Why do you require an Omni Account?': 'https://support.omnigroup.com/omni-accounts/',
    //eslint-disable-next-line
    'Do you offer a trial?': 'https://support.omnigroup.com/trial-versions/',
    //eslint-disable-next-line
    "What's your refund policy?": 'https://support.omnigroup.com/our-refund-policy/',
    //eslint-disable-next-line
    'How do your licenses work?': 'https://support.omnigroup.com/about-our-software-licenses/',
    //eslint-disable-next-line
    'Do I have to purchase the app separately for Mac and iPhone/iPad?': 'https://support.omnigroup.com/sales-apps-sold-separately/',
    //eslint-disable-next-line
    'How do I purchase/manage purchases for employees and teams at my company?': 'https://support.omnigroup.com/team-subscriptions/',
    //eslint-disable-next-line
    'Can I share this with my family?': 'https://support.omnigroup.com/sales-omni-account-family-sharing/',
    'Do you offer education discounts?': 'https://support.omnigroup.com/sales-educational-pricing/',
    //eslint-disable-next-line
    'Do you offer business pricing?': 'https://support.omnigroup.com/sales-business-pricing/',
    //eslint-disable-next-line
    'Can I purchase all of your apps in one bundle?': 'https://support.omnigroup.com/sales-apps-sold-separately/#bundle-discounts',
    //eslint-disable-next-line
    'Do you work with resellers?': 'https://support.omnigroup.com/sales-resellers/',
}

const FAQBlock: React.FC = () => {

    return (
        <FAQContainer>
            <TopPortion>Frequently Asked Questions</TopPortion>
            <ul>
                {Object.keys(FAQ).map((question) => (
                    <li key={question}>
                        <a href={FAQ[question]}>{question}</a>
                    </li>
                ))}
            </ul>
        </FAQContainer>
    )
}

export default FAQBlock;