import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import { useNavigate } from 'react-router-dom';
import styled, {css} from 'styled-components';

import SubscriptionCard from './SubscriptionCard';
import ProductFeatures from './ProductFeatures';
import ToggleSwitch from './ToggleSwitch';
import { SubscriptionFeatures } from '../constants/products';
import { addSubscriptionToCart } from '../actions/cart';

interface StyleProps {
    backgroundColor?: string;
    color?: string;
    offtw?: boolean;
}

const SubscriptionBlockWrapper = styled.div<StyleProps>`
    max-width: 760px;
    width: 100%;
    border-radius: 10px;
    background: ${(props) => props.backgroundColor};
    box-shadow: ${(props) => `0 0 10px 2px ${props.color}`};
`;

const SubscriptionHeader = styled.div`
    font-size: 34px;
    font-weight: 500;
    padding: 30px;
    text-align: center;
    line-height: 1.2;

    > div {
        font-weight: 400;
        font-size: 24px;
    }

    @media screen and (max-width: 650px) {
        padding: 30px 20px;
    }
`;

const SubscriptionInfo = styled.div`
    font-size: 16px;
    padding: 0 30px;
    margin: 0px;

    > span {
        font-weight: 500;
    }
`;

const SubscriptionBlockContainer = styled.div<StyleProps>`
    padding: 30px;
    ${(props) => props.offtw && css`padding-top: 0;`};
`;

interface SubscriptionBlockProps {
    backgroundColor: string;
    borderSideGradient: string;
    blockableVendingIds: Array<string>;
    buttonColor: string;
    color: string;
    header: string;
    platform: string;
    productDisplayName: string;
    productName: string;
    quantity: number;
    requirementString?: string;
    shortName: 'omnigraffle' | 'omnifocus' | 'omnioutliner' | 'omniplan';
    subheader?: string;
    offtw: boolean;
    payInterval: boolean;
    monthlyPrice?: number;
    monthlyStripeID?: string;
    yearlyPrice: number;
    yearlyStripeID: string;
}
const SubscriptionBlock: React.FC<SubscriptionBlockProps> = (props) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { trackEvent } = useMatomo();

    const cartItems = useSelector((state: OmniStore.GlobalReduxState) => ({cart: state.cart.cart }));
    const has_auth = useSelector((state: OmniStore.GlobalReduxState) => state.accounts.has_auth);
    
    const currentSubscriptions = useSelector((state: OmniStore.GlobalReduxState) => ({subscriptions: state.subscriptions.subscriptions }));
    const currentPurchasesFromAccounts = useSelector((state: OmniStore.GlobalReduxState) => state.accounts);

    const [finishedLoading, setFinished] = React.useState<boolean>(false);
    const [inCart, setInCart] =  React.useState<boolean>(false);
    const initialQuantity = props.quantity ? props.quantity : 2;
    const [quantity, setQuantity] =  React.useState(initialQuantity);
    const [quantityError, setQuantityError] =  React.useState<boolean>(false);
    const [subscribed, setSubscribed] =  React.useState<boolean>(false);
    const [cautiouslySubscribe, setCautiousSubscribed] =  React.useState<boolean>(false);
    
    /* used for pay monthly/annually toggle selection */
    const [annualContent, setAnnualContent] =  React.useState<boolean>(props.payInterval ? false : true);

    const borderBackground = `url(https://www.omnigroup.com/assets/img/storefront/${props.shortName}-side-border.svg) repeat, ${props.borderSideGradient}`;
    const features = SubscriptionFeatures(props.requirementString);

    React.useEffect(() => {
        if (quantity <= 0) {
            setQuantity(1)
            // setQuantityError(true);
        } else {
            setQuantityError(false);
        }
    }, [quantity]);

    /* The following looks to see if there’s already a SubscriptionCartItem with the current
    SubscriptionBlock prop of `displayName`. If it does, we toggle the breakSwitch
    and then set inCart to `true` */
    React.useEffect(() => {
        cartItems.cart.subscriptions.map((value) => {
            if (value.displayName === props.productDisplayName) {
                setQuantity(value.quantity)
                setInCart(true);
            }
            return null;
        });
        
        if (!currentPurchasesFromAccounts.purchasesData) return;
        if (currentPurchasesFromAccounts.purchasesData.length > 0) {
            currentPurchasesFromAccounts.purchasesData.map((purchase) => {
                const { vending_product_id, subscription_state, source } = purchase;

                if ('vending_product_id' in purchase) {
                    if (props.blockableVendingIds.includes(vending_product_id) && subscription_state === 'active') {
                        if (source === 'stripe') {
                            setSubscribed(true);
                        } else if (source !== 'stripe') {
                            setCautiousSubscribed(true);
                        }
                    }
                }
                return true;
            });
        }
        setFinished(true);
    }, [
        cartItems,
        props.productDisplayName,
        props.monthlyStripeID,
        props.yearlyStripeID,
        currentPurchasesFromAccounts.purchasesData,
        props.blockableVendingIds,
        has_auth
    ]);

    React.useEffect(() => {
        if (has_auth === false) {
            setFinished(true);
            return;
        }
        // Check the currently subscribed-to subscriptions for team product_ids, then allow them to modify the number of seats on that subscription
        if (currentPurchasesFromAccounts.purchasesData === undefined) return;

        if (currentPurchasesFromAccounts.purchasesData.length > 0) {
            currentPurchasesFromAccounts.purchasesData.map((purchase) => {
                if ('product_id' in purchase) {
                    if (
                        purchase.subscription_state === 'active' &&
                        (purchase.product_id === props.monthlyStripeID ||
                            purchase.product_id === props.yearlyStripeID)
                    ) {
                        setQuantity(purchase.seats);
                        setSubscribed(true);
                    }
                }
                return true;
            });
        }
    }, [
        currentPurchasesFromAccounts,
        props.monthlyStripeID,
        props.yearlyStripeID,
        has_auth,
    ]);

    const addItemToCartHandler = () => {
        let alreadySubscribed = false;
        if (finishedLoading === false) return;

        if (currentSubscriptions.subscriptions.length > 0) {
            currentSubscriptions.subscriptions
                .filter((subscription) => {
                    return subscription.subscription_state === 'active';
                })
                .filter((subscription) => {
                    if (
                        subscription.plan.id === props.monthlyStripeID ||
                        subscription.plan.id === props.yearlyStripeID ||
                        (subscription.plan.id.includes('OMNIFOCUS_ALL') &&
                            props.productDisplayName.includes('Web'))
                    ) {
                        alreadySubscribed = true;
                    }
                    return true;
                });
        }
        if (!alreadySubscribed) {
            dispatch(
                addSubscriptionToCart({
                    item: {
                        blockableVendingIds: props.blockableVendingIds,
                        dateAddedToCart: Date.now(),
                        displayName: props.productDisplayName,
                        monthlyPrice: props.monthlyPrice,
                        monthlyStripeID: props.monthlyStripeID,
                        payInterval: annualContent ? 'YEARLY' : 'MONTHLY',
                        quantity: quantity,
                        shortName: props.shortName,
                        yearlyPrice: props.yearlyPrice,
                        yearlyStripeID: props.yearlyStripeID,
                    },
                })
            );
            trackEvent({
                action: 'Cart Change',
                category: 'Ecommerce',
                name: 'Added to cart: ' + props.productDisplayName, // optional
                value: annualContent ? props.yearlyPrice / 100 : props.monthlyPrice / 100,
            });
            navigate('/cart');
        }
    }

    return (
        <SubscriptionBlockWrapper backgroundColor={props.backgroundColor} color={props.color}>
            <SubscriptionHeader>
                {props.header}
                {props.subheader && <div>{props.subheader}</div>}
            </SubscriptionHeader>
            {props.offtw ? (
                <>
                    <SubscriptionInfo>Web companion access for OmniFocus perpetual license owners.</SubscriptionInfo>
                    <SubscriptionInfo>Requires existing OmniFocus for Mac, iPad, iPhone, or Apple Vision Pro setup.</SubscriptionInfo>
                </>
            ) : (
                <>
                    <SubscriptionInfo>Includes <span>{props.platform}</span> access.</SubscriptionInfo>
                    <SubscriptionInfo>Access to latest Pro version of {props.productName}.</SubscriptionInfo>
                    <SubscriptionInfo>No major version upgrade cost.</SubscriptionInfo>
                </>
            )}
            {props.offtw ? (
                <ToggleSwitch 
                    offtw={props.offtw}
                    leftLabel="Pay Monthly"
                    rightLabel='Pay Annually'
                    showContent={annualContent}
                    toggleChange={setAnnualContent}
                />
            ) : null}
            <SubscriptionBlockContainer offtw={props.offtw}>
                {annualContent ? (
                    <SubscriptionCard 
                        borderBackground={borderBackground}
                        buttonColor={props.buttonColor}
                        offtw={props.offtw}
                        productDisplayName={props.productDisplayName}
                        finishedLoading={finishedLoading}
                        inCart={inCart}
                        shortName={props.shortName}
                        payInterval="year"
                        price={props.yearlyPrice}
                        quantity={quantity}
                        subscribed={subscribed}
                        cautiouslySubscribe={cautiouslySubscribe}
                        addItemToCartHandler={addItemToCartHandler}
                        setQuantity={setQuantity}
                    />
                    ) : (
                    <SubscriptionCard 
                        borderBackground={borderBackground}
                        buttonColor={props.buttonColor}
                        offtw={props.offtw}
                        productDisplayName={props.productDisplayName}
                        finishedLoading={finishedLoading}
                        inCart={inCart}
                        quantity={quantity}
                        shortName={props.shortName}
                        payInterval="month"
                        price={props.monthlyPrice}
                        subscribed={subscribed}
                        cautiouslySubscribe={cautiouslySubscribe}
                        addItemToCartHandler={addItemToCartHandler}
                        setQuantity={setQuantity}
                    />)}
                </SubscriptionBlockContainer>
            <ProductFeatures features={features} purchaseType={"subscription"} focus={props.shortName}/>
        </SubscriptionBlockWrapper>
    )
};

export default SubscriptionBlock;