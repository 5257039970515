//Colors
export const blackColor = '#000000';
export const brightRed = 'rgba(255, 0, 0, 0.71)';
export const darkGray = '#7F7F7F';
export const darkerGray =' #404040';
export const disabledLightGray = '#f2f2f2';
export const signinDisabledGray = "#969696";
export const borderQuantityGray = "#808080";
export const lightestGray = '#f0f0f0';
export const lightGray = "#ccc";
export const lightBlue = 'rgba(105, 201, 237, 1)';
export const blue = 'rgba(48, 140, 255, 1.00)';
export const whiteColor = '#ffffff';
export const errorBoxLightYellow = '#FFFFBF';
export const transparentWhite = 'rgba(255,255,255, 0.5)';
export const transparentBlack = 'rgba(0, 0, 0, 0.5)';

export const backgroundWhite = '#FFFCF0';

//OmniFocus colors
export const backgroundPurpleGradient = 'linear-gradient(#FFFCF0, #CCABFF)';
export const purpleBorderGradientTop = 'linear-gradient(to right, #893BFF, #D092FF)';
export const purpleBorderGradientSide = 'linear-gradient(#D092FF, #893BFF)';
export const omnifocusButtonColor = '#FFD800';
export const purple = '#893BFF';

//OmniGraffle colors
export const backgroundGreenGradient = 'linear-gradient(#FFFCF0, #89E5AF)';
export const greenBorderGradientTop = 'linear-gradient(to right, #0CE565, #C5EEB2)';
export const greenBorderGradientSide = 'linear-gradient(#C5EEB2, #0CE565)';
export const omnigraffleButtonColor = '#FF73A6';
export const green = '#0CE565';

//OmniOutliner colors
export const backgroundOrangeGradient = 'linear-gradient(#FFFCF0, #FA9563)';
export const orangeBorderGradientTop = 'linear-gradient(to right, #FA6216, #FAB464)';
export const orangeBorderGradientSide = 'linear-gradient(#FAB464, #FA6216)';
export const omnioutlinerButtonColor = "#32CCFF";
export const orange = '#FA6216';

//OmiPlan colors
export const backgroundYellowGradient = 'linear-gradient(#FFFCF0, #FFD426)';
export const yellowBorderGradientTop = 'linear-gradient(to right, #FFCC00, #EEE67D)';
export const yellowBorderGradientSide = 'linear-gradient(#EEE67D, #FFCC00)';
export const omniplanButtonColor = "#4E56FF";
export const yellow = '#FFCC00';