import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import styled from 'styled-components';

import ToggleSwitch from './ToggleSwitch';
import BuyLicense from './BuyLicense';
import UpgradeLicense from './UpgradeLicense';
import { addLicenseToCart } from '../actions/cart';
import { LicenseFeatures } from '../constants/products';

import * as vars from '../styles/variables';

interface StyleProps {
    color?: string;
    backgroundColor?: string;
    borderHighlight?: boolean;
    product?: string;

}

const LicenseWrapper = styled.div<StyleProps>`
    max-width: 760px;
    width: 100%;
    border-radius: 10px;
    background: ${(props) => props.backgroundColor};
    box-shadow: ${(props) => `0 0 10px 2px ${props.color}`};

    > h1 {
        text-align: center;
        font-weight: 500;
    }
    > a {
        text-align: center;
    }
`;

const SpecialOffer = styled.p`
    display: block;
    justify-content: center;
    padding: 7px;
    border-radius: 5px;
    background-color: ${vars.backgroundWhite};

    > span {
        font-weight: 500;
    }

    @media screen and (max-width: 700px) {
        width: 300px;
    }
`;

const LicenseHeader = styled.div`
    font-size: 34px;
    font-weight: 500;
    padding: 30px;
    text-align: center;
    line-height: 1;

    @media screen and (max-width: 650px) {
        padding: 20px;
    }
`;

const LicenseBlockContainer = styled.div<StyleProps>`
    border-radius: 10px;
`;

const LicenseInfo = styled.p`
    font-size: 16px;
    padding: 0 30px;
    margin: 0px;

    > span {
        font-weight: 500;
    }
`;

const OutlinerMessage = styled.div`
    display: flex;
    flex-direction: row;
    padding: 15px 30px;

    > span {
        margin: 0 10px;
    }
`;

interface LicenseBlockProps extends OmniStore.LicenseCartItem {
    backgroundColor: string;
    borderTopGradient: string;
    buttonColor: string;
    color: string;
    dateAddedToCart: number;
    header?: string;
    subheader: Array<string>;
    omnioutlinerMessage?: string;
    platform?: string;
    products: Array<OmniStore.ProductInfo>;
    productVersion: string;
    productNumber: number;
    requirementString: string;
    shortName: 'omnigraffle' | 'omnifocus' | 'omnioutliner' | 'omniplan';
    specialOffer?: string;
    universalIOSCounterparts?: Array<string>; //could be OMNIPLAN_4_IOS_STD or OMNIPLAN_4_IOS_PRO, and only OmniPlan right now
}

const LicenseBlock: React.FC<LicenseBlockProps> = (props) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { trackEvent } = useMatomo();

    const initialQuantity = props.quantity ? props.quantity : 2;
    
    const accounts = useSelector((state: OmniStore.GlobalReduxState) => state.accounts);
    const cartItems = useSelector((state: OmniStore.GlobalReduxState) => ({cart: state.cart.cart }));
    const licenseUpgrades = useSelector((state: OmniStore.GlobalReduxState) => ({upgrades: state.upgrades}));
    const upgrades = useSelector((state: OmniStore.GlobalReduxState) => state.upgrades[props.shortName]);

    const [finishedLoading, setFinished] = useState(false);
    const [inCart, setInCart] = useState<boolean>(false);
    const [proQuantity, setProQuantity] = useState<number>(initialQuantity);
    const [selection, setSelection] = useState<'STANDARD' | 'PRO'>(props.standardOrPro);
    const [standardQuantity, setStandardQuantity] = useState<number>(initialQuantity);
    const [standardQuantityError, setStandardQuantityError] = useState<boolean>(false);
    const [proQuantityError, setProQuantityError] = useState<boolean>(false);
    const [upgradeContent, setUpgradeContent] = useState<boolean>(false);
    const [initalContentLoad, setInitialContent] = useState<boolean>(false);

    const borderBackground = `url(https://www.omnigroup.com/assets/img/storefront/${props.shortName}-top-border.svg) repeat, ${props.borderTopGradient}`;
    const features = LicenseFeatures(props.requirementString);

    useEffect(() => {
        if (standardQuantity <= 0) {
            // setStandardQuantity(1)
            setStandardQuantityError(true);
        } else if (proQuantity <= 0) {
            // setProQuantity(1)
            setProQuantityError(true);
        } else {
            setStandardQuantityError(false);
            setProQuantityError(false);
        }

    }, [standardQuantity, proQuantity]);

    useEffect(() => {
        cartItems.cart.licenses.map((value) => {
            if (value.displayName === props.displayName) {
                setInCart(true);
            }
            return null;
        });
        setFinished(true);
    }, [
        cartItems,
        props.displayName,
        accounts.purchasesData
    ])

    useEffect(() => {
        const omniProduct = props.shortName;
        const availableUpgrades = licenseUpgrades.upgrades[omniProduct];
        if (availableUpgrades && (initalContentLoad === false)) {
            setUpgradeContent(true);
            setInitialContent(true);
        }
    }, [licenseUpgrades])

    const addLicenseToCartHandler = async (productID: string, price: number) => {
        dispatch(
            addLicenseToCart({
                item: {
                    dateAddedToCart: Date.now(),
                    displayName: props.displayName,
                    price: price,
                    productID,
                    quantity: selection === 'PRO' 
                        ? proQuantity 
                        : standardQuantity,
                    shortName: props.shortName,
                    standardOrPro: selection,
                },
            })
        );

        trackEvent({
            action: 'Cart Change',
            category: 'Ecommerce',
            name: 'Added to cart: ' + props.displayName, // optional
            value: price
        });
        navigate('/cart');
    };

    return (
        <LicenseWrapper backgroundColor={props.backgroundColor} color={props.color}>
            {props.specialOffer && (
                <SpecialOffer>
                    <span>Special Offer:</span> {props.specialOffer}
                </SpecialOffer>
            )}
            <LicenseHeader>{props.header}</LicenseHeader>
            <LicenseInfo>Purchase once to own this forever. Includes all {props.productNumber}.x updates.</LicenseInfo>
            {(props.shortName === 'omnifocus' || props.shortName === 'omniplan') ? (
                <LicenseInfo>Universal purchase- includes <span>{props.platform}</span> access.</LicenseInfo>
            ) : (
                <LicenseInfo style={{fontSize: '16px', fontWeight: 500}}>License only valid for the Mac application.</LicenseInfo>
            )}
            <LicenseBlockContainer color={props.color}>
                <ToggleSwitch 
                    offtw={false}
                    leftLabel={'Buy'}
                    rightLabel={'Upgrade'}
                    showContent={upgradeContent}
                    toggleChange={setUpgradeContent}
                />
                { upgradeContent ? (
                    <>
                        <UpgradeLicense
                            borderBackground={borderBackground}
                            buttonColor={props.buttonColor}
                            features={features}
                            productVersion={props.productVersion}
                            shortName={props.shortName}
                        />
                        {(props.omnioutlinerMessage && upgrades) && (
                            <OutlinerMessage>
                                <span>¹</span>
                                <div>{props.omnioutlinerMessage}</div>
                            </OutlinerMessage>
                        )}
                    </>
                ) : (
                    <BuyLicense
                        borderBackground={borderBackground}
                        buttonColor={props.buttonColor}
                        dateAddedToCart={props.dateAddedToCart}
                        displayName={props.displayName}
                        finishedLoading={finishedLoading}
                        features={features}
                        header={props.subheader}
                        inCart={inCart}
                        products={props.products}
                        proQuantity={proQuantity}
                        proQuantityError={proQuantityError}
                        selection={selection}
                        shortName={props.shortName}
                        standardQuantity={standardQuantity}
                        standardQuantityError={standardQuantityError}
                        usingUpgradePricing={false}
                        addLicenseToCartHandler={addLicenseToCartHandler}
                        setProQuantity={setProQuantity}
                        setSelection={setSelection}
                        setStandardQuantity={setStandardQuantity}
                        setStandardQuantityError={setStandardQuantityError}
                        setProQuantityError={setProQuantityError}
                    />
                )} 
            </LicenseBlockContainer>
        </LicenseWrapper>
    );
}

export default LicenseBlock;
