import * as React from 'react';
import styled from 'styled-components/macro';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useMatomo } from '@datapunt/matomo-tracker-react';

import { PriceBox } from './PriceBox';
import { RowElement } from './RowElement';
import * as vars from '../styles/variables';

import {
    removeSubscription,
    togglePayInterval,
    convertSubscriptionToTeam,
} from '../actions/cart';

interface StyledProps {
    borderHighlight: boolean;
}

const SelectedPrice = styled.div`
    display: flex;
    flex-direction: row;

    @media (max-width: 500px) {
        font-size: 20px;
        flex-direction: column;
    }
`;

const Quantity = styled.input<StyledProps>`
    width: 65px;
    padding: 6px 8px;
    margin: 0 10px;
    border-color: ${(props): string =>
        props.borderHighlight ? 'red' : 'rgba(85, 197, 235, 1)'};
    font-family: quatro;
    font-size: 16px;
    line-height: 20px;
    height: unset;
`;

const Topper = styled.div`
    font-size: 20px;
    text-align: left;
    margin: 0 10px 0 auto;

    @media (max-width: 500px) {
        margin: 10px auto;
    }
`;

const PriceTag = styled.span`
    font-size: 20px;
    font-weight: 400;
`;

const PriceString = styled.span`
    font-size: 20px;
    font-weight: 700;
    margin-right: 10px;
`;

const Update = styled.a`
    font-size: 14px;
    margin: 0 10px 0;
    text-decoration: underline;
    cursor: pointer;
    font-weight: 400;
`;

const CartItemAppHeader = styled.h3`
    margin: 5px 0;
    font-size: 24px;
    font-weight: 500;
    line-height: 1.4em;

    @media (max-width: 500px) {
        font-size: 20px;
        text-align: center;
    }
`;

const CartItemDetails = styled.div`
    display: flex;
    flex-direction: column;
`;

const CartItemHolderContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 10px 0;
    margin: 0;
    border-bottom: 1px solid rgba(105, 201, 237, 1);
    color: ${vars.blackColor};

    @media (max-width: 500px) {
        padding: 10px;
        border: none;
        border-bottom: 1px solid #909090;
        border-radius: unset;
    }

    @media (max-width: 800px) {
        margin: 20px 0;
    }
`;

interface CartItemHolderProps {
    item: OmniStore.SubscriptionCartItem;
    simple: boolean;
}

const CartItemHolder: React.FC<CartItemHolderProps> = (props) => {
    const dispatch = useDispatch();
    const { trackEvent } = useMatomo();

    const [quantityError, setQuantityError] = React.useState<boolean>(false);
    const initialQuantity = props.item.quantity ? props.item.quantity : 1;
    const [quantity, setQuantity] = React.useState(initialQuantity);

    const convertToTeamHandler = (): void => {
        if (quantity === 0) {
            setQuantityError(true);
            return;
        }
        setQuantityError(false);

        dispatch(
            convertSubscriptionToTeam({
                item: {
                    ...props.item,
                    quantity: quantity,
                },
            })
        );
        trackEvent({
            action: 'Cart Change',
            category: 'Ecommerce',
            name: 'Convert to team: ' + props.item.displayName, // optional
        });
    };

    const removeSubscriptionHandler = (): void => {
        dispatch(
            removeSubscription({
                item: {
                    ...props.item,
                },
            })
        );
        trackEvent({
            action: 'Cart Change',
            category: 'Ecommerce',
            name: 'Item removed: ' + props.item.displayName, // optional
        });
    };

    return (
        <CartItemHolderContainer>
            <CartItemDetails>
                <CartItemAppHeader>{props.item.displayName}</CartItemAppHeader>
                {!props.simple && (
                    <div>
                        <RowElement alignMiddle>
                            <SelectedPrice>
                                {props.item.monthlyPrice && (
                                    <PriceBox 
                                        selected={props.item.payInterval === 'MONTHLY'}
                                        onClick={() => dispatch(togglePayInterval(
                                            {item: {
                                                ...props.item, 
                                                payInterval: 'MONTHLY'
                                            }}
                                        ))}
                                    >
                                        <FormattedNumber
                                            value={props.item.monthlyPrice / 100}
                                            style={`currency`}
                                            currency='USD'
                                        />
                                    </PriceBox>
                                )}
                                <PriceBox
                                    selected={props.item.payInterval === 'YEARLY'}
                                    onClick={() => dispatch(togglePayInterval(
                                        {item: {
                                            ...props.item,
                                            payInterval: 'YEARLY',
                                        }}
                                    ))}
                                >
                                    <FormattedNumber
                                        value={props.item.yearlyPrice / 100}
                                        style={`currency`}
                                        currency='USD'
                                    />
                                    {'/'}
                                    Year
                                </PriceBox>
                            </SelectedPrice>

                            <Topper>
                                <PriceString>
                                    <FormattedMessage id='priceString' />
                                    {': '}
                                </PriceString>
                                <PriceTag>
                                    <FormattedNumber
                                        value={
                                            props.item.payInterval === 'MONTHLY'
                                                ? (props.item.monthlyPrice /
                                                      100) *
                                                  props.item.quantity
                                                : (props.item.yearlyPrice /
                                                      100) *
                                                  props.item.quantity
                                        }
                                        style={`currency`}
                                        currency='USD'
                                    />
                                </PriceTag>
                            </Topper>
                        </RowElement>
                        <RowElement alignEnd alignMiddle>
                            <div>
                                <label
                                    style={{
                                        fontSize: '14px',
                                    }}
                                >
                                    Quantity:
                                </label>
                                <Quantity
                                    type='number'
                                    placeholder='1'
                                    min='1'
                                    pattern='[0-9]*'
                                    value={quantity}
                                    borderHighlight={quantityError}
                                    onChange={(e): void =>
                                        setQuantity(Number(e.target.value))
                                    }
                                />
                            </div>
                            <Update id="remove-update" onClick={() => convertToTeamHandler()}>
                                Update
                            </Update>
                            <Update id="remove-update" onClick={() => removeSubscriptionHandler()}>
                                Remove
                            </Update>
                        </RowElement>
                    </div>
                )}
                {props.simple && (
                    <h3>
                        <FormattedNumber
                            value={
                                props.item.payInterval === 'MONTHLY'
                                    ? (props.item.monthlyPrice / 100) *
                                      props.item.quantity
                                    : (props.item.yearlyPrice / 100) *
                                      props.item.quantity
                            }
                            style={`currency`}
                            currency='USD'
                        />{' '}
                        /{' '}
                        <FormattedMessage
                            id={
                                props.item.payInterval === 'MONTHLY'
                                    ? 'wordForMonth'
                                    : 'wordForYear'
                            }
                        />
                    </h3>
                )}
            </CartItemDetails>
        </CartItemHolderContainer>
    );
};

export default CartItemHolder;
